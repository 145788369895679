import IconMenu from 'anchor-ui/icon-menu';
import MenuItem from 'anchor-ui/menu-item';
import {
  IconClose,
  IconMore,
  IconPerson,
  IconConversation,
  IconReport,
  IconThumbsUp,
  IconRemoveFriend,
  IconBlock,
} from 'anchor-ui/icons';
import { checkIfUserIsBlocked } from '../../../utils/checkIfUserIsBlocked';
import { FriendRequest } from '../../../utils/class/FriendRequest';
import { checkIfUserCanBeCalled } from '../../../utils/CallFunctions';
import { getProfileFromMetadata } from '../../../utils/updateMetadata';
import { checkIfUserIsAdmin } from '../../../utils/checkIfUserIsAdmin';

const FriendButtons = ({
  friend,
  showUserProfile,
  startPrivateChat,
  setUserToReport,
  setFriendToDelete,
  loggedInUser,
  blockedUsers,
  handleBlockUser,
  handleAcceptFriendRequest,
  handleDenyFriendRequest,
  handleCancelFriendRequest,
  isIncomingRequest,
  isOutgoingRequest,
}: {
  friend: CometChat.User | FriendRequest;
  showUserProfile: (user: CometChat.User) => void;
  startPrivateChat: (user: CometChat.User) => void;
  setUserToReport: (userToReport: { user: CometChat.User; guid?: string } | null) => void;
  setFriendToDelete: (friend: CometChat.User | null) => void;
  blockedUsers: CometChat.User[];
  handleBlockUser: (userId: string) => void;
  loggedInUser: CometChat.User | null;
  handleAcceptFriendRequest?: (friend: FriendRequest) => void;
  handleDenyFriendRequest?: (friend: FriendRequest) => void;
  handleCancelFriendRequest?: (friend: FriendRequest) => void;
  isIncomingRequest?: boolean;
  isOutgoingRequest?: boolean;
}) => {
  const userProfile = getProfileFromMetadata(friend as CometChat.User);
  const loggedInUserIsAdmin = loggedInUser ? checkIfUserIsAdmin(loggedInUser) : false;

  const visibility = userProfile?.settings?.profileVisibility || 'public';

  const canShowItem = (visibility: string) => {
    return (
      visibility === 'public' ||
      visibility === 'friends' ||
      loggedInUserIsAdmin
    );
  };

  return (
    <IconMenu icon={<IconMore />}>
      {canShowItem(visibility) ? (
        <MenuItem
          text="Bekijk Profiel"
          icon={<IconPerson />}
          onClick={() => showUserProfile(friend as CometChat.User)}
        />
      ) : <></>}

      <MenuItem
        text="Start Privé Kanaal"
        icon={<IconConversation />}
        onClick={() => startPrivateChat(friend as CometChat.User)}
      />

      <MenuItem
        text="Rapporteer Gebruiker"
        icon={<IconReport />}
        onClick={() => setUserToReport({ user: friend as CometChat.User })}
      />

      {isIncomingRequest && handleAcceptFriendRequest ? (
        <MenuItem
          text="Verzoek Accepteren"
          icon={<IconThumbsUp />}
          onClick={() => handleAcceptFriendRequest(friend as FriendRequest)}
        />
      ) : <></>}

      {isIncomingRequest && handleDenyFriendRequest ? (
        <MenuItem
          text="Verzoek Afwijzen"
          icon={<IconClose />}
          onClick={() => handleDenyFriendRequest(friend as FriendRequest)}
        />
      ) : <></>}

      {isOutgoingRequest && handleCancelFriendRequest ? (
        <MenuItem
          text="Verzoek Annuleren"
          icon={<IconClose />}
          onClick={() => handleCancelFriendRequest(friend as FriendRequest)}
        />
      ) : <></>}

      {!isOutgoingRequest && !isIncomingRequest ? (
        <MenuItem
          text="Verwijder vriend"
          icon={<IconRemoveFriend />}
          onClick={() => setFriendToDelete(friend as CometChat.User)}
        />
      ) : <></>}

      <MenuItem
        text={
          checkIfUserIsBlocked(blockedUsers, friend.getUid())
            ? 'Deblokkeer gebruiker'
            : 'Blokkeer gebruiker'
        }
        icon={<IconBlock />}
        onClick={() => handleBlockUser(friend.getUid())}
      />

      {checkIfUserCanBeCalled(friend as CometChat.User, true) ? (
        <MenuItem
          text="Start audio of videogesprek"
          icon={<IconReport />}
          onClick={() => {
            // Add your call logic here
          }}
        />
      ) : <></>}
    </IconMenu>
  );
};

export default FriendButtons;
