import { CometChat } from "@cometchat/chat-sdk-javascript";

import Message from 'anchor-ui/message';
import MessageSeparator from 'anchor-ui/message-separator';
import ImageLoader from 'anchor-ui/image-loader';
import IconMenu from 'anchor-ui/icon-menu';
import MenuItem from 'anchor-ui/menu-item';
import {
  IconChevronDown,
  IconDelete,
  IconEdit,
  IconReport,
  IconBlock,
  IconThumbsDown,
  IconHammer,
  IconConversation,
  IconHand,
  IconExit,
  IconTravel,
  IconHeart,
  IconAddImage,
  IconMute,
} from 'anchor-ui/icons';
import Divider from 'anchor-ui/divider';

import defaultAvatar from "./../../assets/default_avatar.jpg";
import PollMessage from './PollMessage';
import ImageDialog from './ImageDialog';
import { useState } from 'react';
import { ActiveChannelInterface } from '../../interfaces/activeChannelInterface';
import { getFontSizeStyle } from '../../utils/getFontSizeStyle';
import { UserSettingsInterface } from '../../interfaces/userSettingsInterface';
import { getActiveColor } from '../../utils/activeColor';
import { ReplyMessageInterface } from '../../interfaces/replyMessageInterface';
import { getProfileFromMetadata } from "../../utils/updateMetadata";
import ReactPlayer from 'react-player/youtube';
import { checkUserIsRoyal } from "../../utils/checkSubscription";

interface MessageComponentProps {
  message: CometChat.BaseMessage;
  myMessage: boolean;
  showMessageSeparator: boolean;
  isBlocked: boolean;
  handleDeleteMessage: (messageId: string) => void;
  setEditMessage: (editMessage: CometChat.TextMessage | null) => void;
  setMessageToReport: (messageToReport: CometChat.BaseMessage) => void;
  handleBlockUser: (userId: string) => void;
  groupMembers?: CometChat.GroupMember[];
  loggedInUser?: CometChat.User;
  setCurrentChat: (chat: any) => void;
  setAlert: React.Dispatch<React.SetStateAction<{ message: string; type: string; } | null>>;
  isAdmin: boolean;
  isModerator: boolean;
  loggedInUserIsAdmin: boolean;
  loggedInUserIsModerator: boolean;

  handleMuteUser: (user: CometChat.User, unMute: boolean) => void;
  handleKickUser: (user: CometChat.User, group: CometChat.Group) => void;
  handleLogoutKickUser: (user: CometChat.User) => void;
  handleBanUser: (user: CometChat.User) => void;
  handleDeleteMessageAsAdmin: (message: CometChat.BaseMessage) => void;
  currentChat: ActiveChannelInterface;
  userSettings: UserSettingsInterface;
  setMessageToReply: (message: ReplyMessageInterface) => void;
  setUserToWarn: (user: CometChat.User) => void;
  setMessageToForward: (message: CometChat.BaseMessage) => void;
  setActiveTabIndex: (tab: number) => void;

  handleChangeChannel: (channel: ActiveChannelInterface) => void;
  channels: CometChat.Group[];
}

const formatDate = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  return `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
};

const formatTime = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
};

const MessageComponent: React.FC<MessageComponentProps> = ({
  message,
  myMessage,
  showMessageSeparator,
  isBlocked,
  handleDeleteMessage,
  setEditMessage,
  setMessageToReport,
  handleBlockUser,
  groupMembers,
  loggedInUser,
  setCurrentChat,
  setAlert,
  isAdmin,
  isModerator,
  loggedInUserIsAdmin,
  loggedInUserIsModerator,

  handleMuteUser,
  handleKickUser,
  handleLogoutKickUser,
  handleBanUser,
  handleDeleteMessageAsAdmin,
  currentChat,
  userSettings,
  setMessageToReply,
  setUserToWarn,
  setMessageToForward,
  setActiveTabIndex,

  handleChangeChannel,
  channels,
}) => {

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const avatar = isBlocked ? defaultAvatar : message.getSender()?.getAvatar() || defaultAvatar;
  const username = isBlocked ? '[Geblokkeerde gebruiker]' : message.getSender()?.getName();
  const createdAt = formatTime(message.getSentAt());
  const separator = showMessageSeparator ? <MessageSeparator text={formatDate(message.getSentAt())} /> : null;

  const profile = getProfileFromMetadata(message.getSender());
  const isMuted = profile?.muted || false;

  const imgProps = { style: { height: '200px', width: 'auto', borderRadius: '4px' } };
  const error = <span>Error while loading image!</span>;
  const placeholder = <span>Loading image...</span>;

  const isCustomMessage = message instanceof CometChat.CustomMessage;
  const isImageMessage = message instanceof CometChat.MediaMessage && message.getType() === CometChat.MESSAGE_TYPE.IMAGE;
  const isPollMessage = message instanceof CometChat.CustomMessage && message.getType() === 'extension_poll';
  const isTextMessage = message instanceof CometChat.TextMessage;
  const isGifMessage = isTextMessage && message.getText().startsWith('https://media.tenor.com/') && message.getText().endsWith('.gif');

  const messageTimeStyle = (
    !isBlocked &&
    !userSettings.mediaDisabled &&
    (isImageMessage || isGifMessage)) ? {
    color: 'rgb(254, 254, 254)',
    padding: '4px',
    opacity: 1,
    borderRadius: '4px 0px',
    backgroundColor: 'rgba(21, 21, 21, 0.45)',
    position: 'absolute',
    bottom: '17px',
    right: '12px',
  } : isPollMessage ? {
    float: 'right',
  } : undefined;

  const getMessageText = (message: CometChat.TextMessage) => message.getText();
  const groupMembersWithoutMe = groupMembers?.filter((member) => member.getUid() !== loggedInUser?.getUid());

  const getHighLight = (message: CometChat.TextMessage) => {
    if (!isTextMessage) return [];
    const text = message.getText();
    const taggedUser = groupMembersWithoutMe?.find((member) => new RegExp(`@${member.getName()}\\b`, 'g').test(text))
      || (loggedInUser && new RegExp(`@${loggedInUser.getName()}\\b`, 'g').test(text) ? loggedInUser : null);

    return taggedUser ? [{ id: taggedUser.getUid(), value: taggedUser.getName(), prefix: '@' }] : [];
  };

  const showUserProfile = (user: CometChat.User) => {
    setCurrentChat((prev: any) => ({ ...prev, userProfile: user }));
  };

  const handleHighlightClick = (event: object, highlight: { id: string, value: string, prefix: string }) => {
    if (highlight.id === loggedInUser?.getUid()) return;
    const user = groupMembers?.find((member) => member.getUid() === highlight.id);
    if (user) showUserProfile(user);
  };

  const messageHeaderStyle = {
    ...getFontSizeStyle(userSettings.messageFontSize),
    color: myMessage || (checkUserIsRoyal(message.getSender()?.getRole() ?? "") &&
      profile.settings?.msgBg &&
      profile.settings?.msgBg !== "default") ? "white" : "#757575",
  };

  const messageBodyStyle = {
    ...getFontSizeStyle(userSettings.messageFontSize),
    color: myMessage || (checkUserIsRoyal(message.getSender()?.getRole() ?? "") &&
      profile.settings?.msgBg &&
      profile.settings?.msgBg !== "default") ? "white" : "black"
  };

  const getNameDecoration = (user: CometChat.User) => {
    const role = user.getRole() || "default";
    const userProfile = getProfileFromMetadata(user);

    if (userProfile?.settings?.starDisabled) return "";

    switch (role) {
      case "royal":
        return " 👑";
      case "vip":
        return " 💎";
      case "premium":
        return " ⭐";
      default:
        return "";
    }
  };

  const getBadge = (role: string) => {
    switch (role) {
      case "royal":
        return <span className='text-badge' style={{ backgroundColor: 'gold' }}>Royal</span>;
      case "vip":
        return <span className='text-badge' style={{ backgroundColor: 'purple' }}>VIP</span>;
      case "premium":
        return <span className='text-badge' style={{ backgroundColor: 'orange' }}>Premium</span>;
      default:
        return null;
    }
  };

  const renderMessageBody = () => {
    // Get the bugreport data
    const bugReport = (message as any).metadata?.bugReport || null;
    // Get the message that is being forwarded
    const forwardMessage = (message as any).metadata?.forwardedMessage || null;
    // Get the message that is being replied to
    const replyToMessage = (message as any).metadata?.replyTo || null;
    // Check if botmessage
    const isBotMessage = message.getData().chatbotData || null;

    // If the message is a bugreport, render it
    if (bugReport) {
      const { bugLocation, bugDescription, stepsToReproduce, device, browserAndVersion } = bugReport;
      return (
        <div onClick={() => setIsDialogOpen(true)}>
          {isImageMessage && (
            <ImageLoader
              src={message.getURL()}
              imgProps={imgProps}
              error={error}
              placeholder={placeholder}
            />
          )}
          <p><b>BUG RAPPORTAGE</b></p>
          <hr />
          <p><b>Probleem:</b> {bugDescription}</p>
          <p><b>Locatie probleem:</b> {bugLocation}</p>
          <p><b>Stappen om het probleem te reproduceren:</b> {stepsToReproduce}</p>
          <p><b>Apparaat:</b> {device}</p>
          <p><b>Browser & versie:</b> {browserAndVersion}</p>
        </div>
      );
    }

    // If there is a message being forwarded, render it
    if (forwardMessage) {
      const forwardUsername = forwardMessage.sender.name ?? forwardMessage.sender;
      return (
        <div
          className='reply-message'
          style={myMessage ? { color: "white" } : { borderLeft: `solid 3px ${getActiveColor()}` }}
        >
          <span className="reply-username">{forwardUsername}</span>
          <span className="reply-text">{(message as any).text}</span>
          <small><i>Doorgestuurd</i>  {formatDate(forwardMessage.sentAt)} {formatTime(forwardMessage.sentAt)}</small>
        </div>
      );
    }

    // If there is a message being replied to, render it
    let replyMessage;
    if (replyToMessage) {
      const replyUsername = replyToMessage.sender.name;
      const replyText = replyToMessage.message;

      const replyMessageBody = (
        <div
          className='reply-message'
          style={myMessage ? { color: "white" } : { borderLeft: `solid 3px ${getActiveColor()}` }}
        >
          <span className="reply-username">{replyUsername}</span>
          <span className="reply-text">{replyText}</span>
        </div>
      );

      replyMessage = replyMessageBody;
    }

    // Get the message body
    const getMessageBody = () => {
      if (isBlocked) return 'Bericht geblokkeerd';
      if (message.getDeletedAt()) return 'Dit bericht is verwijderd';

      if (userSettings.mediaDisabled && isImageMessage) {
        return <i>{`<Afbeelding>`}</i>;
      } else if (userSettings.mediaDisabled && isGifMessage) {
        return <i>{`<GIF>`}</i>;
      }

      if ((isImageMessage || isGifMessage)) {
        return (
          <div onClick={() => setIsDialogOpen(true)} style={{ height: "200px" }}>
            <ImageLoader
              src={isGifMessage ? message.getText() : isImageMessage && message.getURL()}
              imgProps={imgProps}
              error={error}
              placeholder={placeholder}
            />
          </div>
        );
      }

      if (isPollMessage) {
        return <PollMessage
          message={message as CometChat.BaseMessage}
          myMessage={(myMessage && userSettings.myMsgAlignment !== 'left') || userSettings.otherMsgAlignment === 'right'}
          setAlert={setAlert}
          loggedInUser={loggedInUser as CometChat.User}
        />
      }

      if (isCustomMessage) {
        const tags = (message.getCustomData() as any).tags;
        if (!tags) return;
        if (tags.includes('reload_pinned')) return (message.getCustomData() as any).messageInput;
      }

      if (isTextMessage) {
        const checkContainsYtLink = (text: string) => {
          const ytRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?([a-zA-Z0-9_-]+)/;
          return ytRegex.test(text);
        };

        const handleExtractYtLink = (text: string) => {
          const ytRegex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?([a-zA-Z0-9_-]+)/;
          const match = text.match(ytRegex);
          return match ? match[0] : '';
        };

        // Function to convert URLs to clickable links
        const linkifyText = (text: string, ytUrl: string) => {
          // Get the appropriate color based on whether it's a sent or received message
          const textColor = myMessage ? "white" : getActiveColor();

          // Replace the YouTube URL with an anchor tag using inline style
          return text.replace(
            ytUrl,
            `<a href="${ytUrl}" style="color: ${textColor};" target="_blank" rel="noopener noreferrer">${ytUrl}</a>`
          );
        };

        const messageText = getMessageText(message as CometChat.TextMessage);
        const containsYtLink = checkContainsYtLink(messageText);

        if (containsYtLink && !userSettings.mediaDisabled) {
          const url = handleExtractYtLink(messageText);
          const linkedText = linkifyText(messageText, url);

          return (
            <>
              <div dangerouslySetInnerHTML={{ __html: linkedText }} />
              <ReactPlayer url={url} width="100%" height="200px" />
            </>
          );
        }

        return messageText;
      }

      return 'Unsupported message type';
    }

    const body = getMessageBody();

    if (isBotMessage) {
      const getChannelToJoin = (channelId: string, onlineMembersCount: number) => {

        const channel = channels.find((channel) => channel.getGuid() === channelId);

        const channelToJoin: ActiveChannelInterface = {
          id: channelId,
          name: channel?.getName() || "",
          icon: "",
          isGroup: true,
          joinedAt: 0,
          onlineMembersCount,
        };
        return channelToJoin;
      }

      return (
        <>
          <div className='bot-message'>
            {(message as any).text} &nbsp;

            <span
              style={{ color: getActiveColor() }}
              className="cursor-pointer hover-underline"
              onClick={() => {
                message.getData().chatbotData?.type === "subscriptionAd" ? setCurrentChat({ id: '', name: '', icon: '', isGroup: false, showSubscriptionPage: true }) :
                  handleChangeChannel(
                    getChannelToJoin(
                      message.getData().chatbotData?.channelId + "",
                      message.getData().chatbotData?.channelOnlineMembersCount || 333
                    )
                  );
              }}
            >
              {message.getData().chatbotData?.type === "subscriptionAd" ? "Bekijk abonnementen 👉" : "Ga naar kanaal 👉"}
            </span>
          </div>
        </>
      );
    }

    // If there is a message being replied to, render it and the message body
    if (replyMessage) {
      return (
        <>
          {replyMessage}
          {body}
        </>
      );
    } else { // If there is no message being replied to, render only the message body
      return body;
    }
  };

  const renderIconMenu = () => (
    <IconMenu icon={<IconChevronDown />}>
      <MenuItem
        text="Webcams 18+"
        icon={<IconAddImage color={getActiveColor()} />}
        onClick={() => window.open("https://www.pikantcams.nl/nl?pi=chatplaza_v2", '_blank')}
      />
      <MenuItem
        text="Dating M/V"
        icon={<IconHeart color={getActiveColor()} />}
        onClick={() => window.open("https://ds1.nl/c/?si=51&li=1646487&wi=250877&ws=", '_blank')}
      />
      <Divider />
      <MenuItem
        text="Antwoord"
        icon={<IconConversation />}
        onClick={() => {
          // replymessage's text should either be getText, 'Afbeelding' or 'GIF'
          const replyText = isImageMessage ? '<Afbeelding>' : isGifMessage ? '<GIF>' : (message as CometChat.TextMessage).getText();

          const replyMessage: ReplyMessageInterface = {
            sender: {
              name: message.getSender()?.getName() as string
            },
            message: replyText,
            messageSentAt: formatTime(message.getSentAt())
          };

          setMessageToReply(replyMessage);
        }}
      />
      {
        isTextMessage && (
          <MenuItem
            text="Stuur door"
            icon={<IconTravel />}
            onClick={() => {
              setMessageToForward(message);
              setActiveTabIndex(1);
            }}
          />
        )
      }
      {myMessage ? (
        <>
          <MenuItem
            text="Verwijder Bericht"
            icon={<IconDelete />}
            onClick={() => handleDeleteMessage(message.getId() + '')}
          />
          {(isTextMessage && !isGifMessage) && (
            <MenuItem
              text="Bewerk Bericht"
              icon={<IconEdit />}
              onClick={() => setEditMessage(message as CometChat.TextMessage)}
            />
          )}
        </>
      ) : (
        <>
          <MenuItem
            text="Rapporteer Bericht"
            icon={<IconReport />}
            onClick={() => setMessageToReport(message)}
          />
          <MenuItem
            text={isBlocked ? "Deblokkeer gebruiker" : "Blokkeer gebruiker"}
            icon={<IconBlock />}
            onClick={() => handleBlockUser(message.getSender()?.getUid() as string)}
          />
          {
            (!isAdmin && !isModerator && (loggedInUserIsAdmin || loggedInUserIsModerator)) && (
              <>
                <Divider />
                <MenuItem
                  text="Verwijder Bericht"
                  icon={<IconDelete color={userSettings.themeColor} />}
                  onClick={() => handleDeleteMessageAsAdmin(message)}
                />
                <MenuItem
                  text="Stuur waarschuwing"
                  icon={<IconHand color={getActiveColor()} />}
                  onClick={() => setUserToWarn(message.getSender())}
                />
                <MenuItem
                  text={`${isMuted ? "Unm" : "M"}ute gebruiker`}
                  icon={<IconMute color={userSettings.themeColor} />}
                  onClick={() => handleMuteUser(message.getSender(), isMuted)}
                />
                <MenuItem
                  text="Kick gebruiker"
                  icon={<IconThumbsDown color={userSettings.themeColor} />}
                  onClick={() => handleKickUser(message.getSender(), new CometChat.Group(currentChat.id, currentChat.name))}
                />
                <MenuItem
                  text="Harde kick"
                  icon={<IconExit color={userSettings.themeColor} />}
                  onClick={() => handleLogoutKickUser(message.getSender())}
                />
                <MenuItem
                  text="Ban gebruiker"
                  icon={<IconHammer color={userSettings.themeColor} />}
                  onClick={() => handleBanUser(message.getSender())}
                />
              </>
            )
          }
        </>
      )}
    </IconMenu>
  );

  return (
    <div id={message.getId() + ''} className={(isImageMessage || isGifMessage) ? 'image-message' : ''}>
      {(isImageMessage || isGifMessage) && (
        <ImageDialog
          isOpen={isDialogOpen}
          setIsOpen={setIsDialogOpen}
          imageUrl={(isGifMessage ? message.getText() : isImageMessage && message.getURL()) as string}
        />
      )}

      {message.getCategory() === CometChat.CATEGORY_MESSAGE && message.getType() === 'action' ? (
        <MessageSeparator text={isTextMessage ? message.getText() : ''} />
      ) : message.getType() === 'groupMember' && message.getType() === 'groupMember' ? (
        <MessageSeparator text={(message as any).getMessage() ?? ''} />
      ) : isCustomMessage && (message.getCustomData() as any).tags?.includes('reload_pinned') ? (
        <MessageSeparator text={(message.getCustomData() as any).messageInput} />
      ) :
        (
          <Message
            avatar={avatar}
            body={renderMessageBody()}
            highlights={isTextMessage ? getHighLight(message as CometChat.TextMessage) : []}
            onHighlightClick={handleHighlightClick}
            messageTimeStyle={messageTimeStyle}
            type={isBlocked ? 'text' : (isImageMessage || isGifMessage) ? 'img' : 'text'}
            image={(isBlocked || !isImageMessage || userSettings.mediaDisabled) ? '' : message.getURL()}
            createdAt={createdAt}
            username={username + getNameDecoration(message.getSender())}
            badge={(isAdmin || isModerator) ?
              <span className='text-badge' style={
                myMessage ? { backgroundColor: "white", color: getActiveColor() } :
                  { backgroundColor: getActiveColor() }
              }>
                {isAdmin ? "Admin" : "Mod"}
              </span> : message.getData().chatbotData ?
                <span className='text-badge' style={
                  myMessage ? { backgroundColor: "white", color: getActiveColor() } :
                    { backgroundColor: getActiveColor() }
                }>
                  bot
                </span>
                : getBadge(message.getSender().getRole())
            }

            myMessage={(myMessage && userSettings.myMsgAlignment !== 'left') || (!myMessage && userSettings.otherMsgAlignment === 'right')}

            separator={separator}
            edited={message.getEditedAt() > 0 && !isPollMessage && 'Bewerkt'}
            iconMenu={renderIconMenu()}

            style={
              (checkUserIsRoyal(message.getSender()?.getRole() ?? "") &&
                profile.settings?.msgBg &&
                profile.settings?.msgBg !== "default") ?
                { backgroundColor: profile.settings.msgBg } :
                myMessage ? { backgroundColor: userSettings.themeColor } :
                  { backgroundColor: "white" }
            }
            messageHeaderStyle={messageHeaderStyle}
            messageBodyStyle={messageBodyStyle}

            color={myMessage || (checkUserIsRoyal(message.getSender()?.getRole() ?? "") &&
              profile.settings?.msgBg &&
              profile.settings?.msgBg !== "default") ? "white" : "black"
            }
            className={checkUserIsRoyal(message.getSender()?.getRole() ?? "") ? `font-${profile.settings?.msgFont}` : ""}
          />
        )}
    </div>
  );
};

export default MessageComponent;